import * as Sentry from '@sentry/gatsby';
import { NEW_API_URL } from '@constants';

Sentry.init({
  dsn: 'https://eb0917c01d7283b21a220c8cd9377c9a@o453014.ingest.us.sentry.io/4506871331618816',
  tunnel: `${NEW_API_URL}/sentry`,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  beforeSend(event) {
    if (event.request?.url?.includes('localhost')) {
      console.log({ localError: event });
      return null;
    }
    return event;
  },
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/centrumakrobatyczne\.pl/, /^https:\/\/api\/centrumakrobatyczne\.pl/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});
